import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateRange, ListItem } from '../../../ComponentLibrary/src';
import { Interval } from '../../../context/Systems';
import { useMounted } from '../../../hooks';
import { ChartControls, ChartSources, QuickRange } from './types';

export const QUICK_RANGE_OPTIONS = ['24h', '7d', '30d', '6M', '12M'];
export const QUICK_RANGE_MINUTES_OPTIONS = ['30m', '1h', '12h'];
export const luxonUnits = {
  m: 'minutes',
  h: 'hours',
  d: 'days',
  M: 'Months',
};
export const DATA_INTERVALS = [
  {
    value: Interval.minute,
    label: 'Minutes',
  },
  {
    value: Interval.hour,
    label: 'Hours',
  },
  {
    value: Interval.day,
    label: 'Days',
  },
  {
    value: Interval.month,
    label: 'Months',
  },
];
export interface DataSearchParams {
  chartSources: ChartSources;
  dateRange: DateRange;
  interval?: Interval;
  quickRange?: QuickRange;
}

export interface DataSearchReturn {
  chartControls: ChartControls;
  setDataSearch: ({ chartSources, dateRange, interval, quickRange }: DataSearchParams) => void;
}

export interface Params {
  from?: string;
  to?: string;
  interval?: string;
  quickRange?: string;
}

/**
 * React hook to set the search params using react router and chart controls state
 * @returns object with chartControls current state and setDataSearch to update URL and state
 */
export const useDataSearchParams = (): DataSearchReturn => {
  const isMounted = useMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [chartControls, setChartControls] = useState<ChartControls>(getOptions(getOptionsFromUrl(searchParams)));
  // set chart controls when search param changes
  useEffect(() => {
    const urlChartControls = getOptions(getOptionsFromUrl(searchParams));
    // update state if chart controls in URL changed, but only after mount or it will trigger a double fetch
    if (isMounted && !compareChartControls(urlChartControls, chartControls)) {
      setChartControls(urlChartControls);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    chartControls,
    setDataSearch: ({ chartSources, dateRange, interval, quickRange }: DataSearchParams): void => {
      const newChartControls = getOptions({ chartSources, dateRange, interval, quickRange });
      if (!compareChartControls(chartControls, newChartControls)) {
        setChartControls(newChartControls);
        const chartSourcesObj: Params = {
          ...(Object.fromEntries(Object.entries(chartSources).map(([key, value]) => [key, value?.toString()])) ?? {}),
        };

        if (quickRange) {
          chartSourcesObj.quickRange = `${quickRange.value}${quickRange.units.charAt(0)}`;
        } else {
          chartSourcesObj.from = (dateRange?.from as Date)?.getTime().toString() ?? '';
          chartSourcesObj.to = (dateRange?.to as Date)?.getTime().toString() ?? '';
        }

        if (interval) chartSourcesObj.interval = interval;

        const newSearch = new URLSearchParams(chartSourcesObj as Record<string, string>);

        setSearchParams(newSearch);
      }
    },
  };
};

export const getOptions = ({
  chartSources,
  dateRange,
  quickRange,
  interval = Interval.hour,
}: DataSearchParams): ChartControls => {
  if (!dateRange.from && !quickRange) {
    quickRange = {
      units: 'days',
      value: 7,
    };
  }

  const results: ChartControls = {
    chartSources,
    dataInterval: interval,
    dateRange: calculateDateRange(quickRange, dateRange),
  };

  if (quickRange) {
    results.quickRange = quickRange;
  }

  return results;
};

const getOptionsFromUrl = (searchParams: URLSearchParams): DataSearchParams => {
  const results: DataSearchParams = {
    chartSources: {},
    dateRange: {},
  };

  Array.from(searchParams).forEach(([key, value]) => {
    if (key === 'from' || key === 'to') {
      results.dateRange[key] = new Date(parseInt(value));
    } else if (key === 'interval') {
      results[key] = value as Interval;
    } else if (key === 'quickRange') {
      const units = value.match(/[a-z]/i);
      const val = value.match(/[0-9]*/);

      results.quickRange = {
        units: luxonUnits[units ? (units[0] as keyof typeof luxonUnits) : 'd'],
        value: val ? parseInt(val[0]) : 7,
      };
    } else {
      results.chartSources[key] = value ? (parseInt(value) as 1 | 2) : undefined;
    }
  });

  results.dateRange = calculateDateRange(results.quickRange, results.dateRange);

  return results;
};

export const calculateDateRange = (quickRange?: QuickRange, dateRange?: DateRange): DateRange => {
  const now = DateTime.now();
  if (quickRange) {
    return {
      from: now
        .minus({
          [quickRange.units]: quickRange.value,
        })
        .toJSDate(),
      to: now.toJSDate(),
    };
  } else {
    return {
      from: dateRange?.from ? dateRange?.from : now.minus({ days: 7 }).toJSDate(),
      to: dateRange?.to ? dateRange?.to : DateTime.now().toJSDate(),
    };
  }
};

export function calculateMinInterval(interval?: Interval, minInterval?: Interval): Interval | undefined {
  if (!interval && minInterval) return minInterval;
  if (!minInterval && interval) return interval;
  if (!minInterval && !interval) return undefined;
  const intervalsOrdered = [Interval.minute, Interval.hour, Interval.day, Interval.month];

  if (intervalsOrdered.indexOf(minInterval ?? Interval.minute) > intervalsOrdered.indexOf(interval ?? Interval.minute))
    return minInterval;
  return interval;
}

export function getDefaultIntervalForQuickRange(quickRange: QuickRange): Interval {
  switch (`${quickRange.value}${quickRange.units?.charAt(0)}`) {
    case '24h':
      return Interval.minute;
    case '7d':
    case '30d':
      return Interval.hour;
    case '6M':
    case '12M':
    default:
      return Interval.day;
  }
}

export const listItemLabelSort = (a: ListItem, b: ListItem): number =>
  (a.label?.toString() ?? '').localeCompare(b.label?.toString() ?? '', 'en', { numeric: true });

/**
 * Compares chart controls objects by changing the to and from to locale strings because lodash date
 * Object comparison is ===
 *
 * @param urlChartControls ChartControls
 * @param chartControls ChartControls
 * @returns true if both objects are equal
 */
export function compareChartControls(urlChartControls: ChartControls, chartControls: ChartControls): boolean {
  let dateRangeToIsSame = urlChartControls.dateRange.to === chartControls.dateRange.to;
  let dateRangeFromIsSame = urlChartControls.dateRange.from === chartControls.dateRange.from;

  if (urlChartControls.dateRange.to && chartControls.dateRange.to) {
    dateRangeToIsSame =
      new Date(urlChartControls.dateRange.to).toLocaleString() ===
      new Date(chartControls.dateRange.to).toLocaleString();
  }

  if (urlChartControls.dateRange.from && chartControls.dateRange.from) {
    dateRangeFromIsSame =
      new Date(urlChartControls.dateRange.from).toLocaleString() ===
      new Date(chartControls.dateRange.from).toLocaleString();
  }

  return (
    isEqual({ ...urlChartControls, dateRange: '' }, { ...chartControls, dateRange: '' }) &&
    dateRangeToIsSame &&
    dateRangeFromIsSame
  );
}
